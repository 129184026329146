import React from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import axios from "axios";
import { useState, useEffect } from "react";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import FlatButton from "../components/Reusables/FlatButton";
import styled from "styled-components";
import UniversityStudents from "../components/Students/UniversityStudents";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../components/Students/EligibilityAndBenefits";
import Accordion from "../components/Reusables/Accordion";
import StatisticBox from "../components/Home/StatisticBlock";
import events1 from "../images/pageImages/Students/Events.png";
import hero from "../images/pageImages/Investment/Hero.jpg";
import Testimonial1 from "../images/pageImages/Law/Student1.jpg";
import Testimonial2 from "../images/pageImages/Law/Student2.jpg";
import rise from "../images/pageImages/Students/Rise.jpg";
import aim from "../images/pageImages/Students/Aim.jpg";
import springboard from "../images/pageImages/Students/Springboard.jpg";
import slaughter from "../images/UpReachPartnerLogos/PartnerEmployerLogos/43.png";
import applyphoto from "../images/pageImages/Law/apply.png";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .apply-now {
    padding: 3% 0;
    background-color: #e43d5a;
  }

  .accordion-start {
    padding-top: 50px;
  }

  .boxHeight {
    height: 600px;
    @media (max-width: 600px) {
      height: 350px;
    }
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .flat-btn-link:hover {
    color: white;
  }

  .section-divider {
    margin-top: 50px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .grew-up {
    padding: 3% 0;
    background-color: #5db9db;
    height: 160px;

    @media (max-width: 600px) {
      height: unset;
      padding: 5% 8%;
    }
  }

  .onhover-display {
    color: white;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    display: none;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .grew-up:hover {
    padding-top: 2%;
  }

  .grew-up:hover .onhover-display {
    display: block;
  }

  .grew-up:hover .flat-button {
    display: none;

    @media (max-width: 600px) {
      display: unset;
    }
  }

  .onhover-links {
    color: white;
  }
  .onhover-links:hover {
    color: white;
  }

  .slaughter-img {
    width: 500px;
  }
`;

const accordion = [
  {
    title: "What is the Law Springboard?",
    description: (
      <div>
        <p>
          The Law Springboard is a structured programme of careers support,
          working with 90 undergraduates each year from underrepresented
          backgrounds.
        </p>
        <p>
          It is designed to equip these undergraduates ("upReach Associates")
          with relevant information and experience of corporate law and the
          legal sector, to enable them to succeed in securing work experience,
          training contracts, and other career opportunities.
        </p>
        <p>
          The Springboard, launched in 2019, is possible through our partnership
          with the international law firm Slaughter and May. Their support
          allows these undergraduates to build their skills, knowledge and
          experience in order to pursue a career in the legal sector, and to
          realise their full potential in applications.
        </p>
      </div>
    ),
  },
  {
    title: "How do you support students on the Law Springboard?",
    description: (
      <div>
        <p>
          Undergraduates on the Law Springboard programme benefit from
          personalised one-to-one support, which helps them with application and
          interview preparation. They also access a wide range of employer
          events, mentoring by lawyers and work experience opportunities.
        </p>
        <p>
          We encourage each student on the programme to complete 8 different
          steps as part of their participation on the programme, to give them
          the best chance of success:
        </p>
        <ol>
          <li>
            Attend an Employer Insight Day, hosted by Slaughter and May or
            another partner employer
          </li>
          <li>
            Participate in a Skills Workshop to develop essential soft skills
          </li>
          <li>Submit a draft of their CV for review </li>
          <li>Submit an application or cover letter for review</li>
          <li>Pass a Mock Interview</li>
          <li>
            Complete a 'Law Career Course' on upReach's online platform, to
            develop essential sector knowledge
          </li>
          <li>
            Complete two Online Tests to maximise success in the application
            process
          </li>
          <li>
            Participate in three online webinars (to develop career knowledge
            and commercial awareness)
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: "What are the main highlights of the Law Springboard Programme?",
    description: (
      <div>
        <p>
          During the programme, a number of events take place designed to
          provide students with an insight into Slaughter and May. These
          include:
        </p>
        <li>
          <b>The Law Springboard Launch Event</b>: an opportunity for the 90
          undergraduates to visit the firm and network with its employees.
        </li>
        <li>
          <b>A Slaughter and May Insight Day</b>: an opportunity for 50
          additional undergraduates to learn about the firm's opportunities and
          application processes.
        </li>
        <li>
          <b>The Slaughter and May Professional Experience Week</b>: a one-week
          work experience opportunity offering 25 undergraduates a chance to
          work alongside both an associate and trainee from the firm.
        </li>
        <p>
          In addition to the above, those on the Law Springboard have the chance
          to be paired with a Slaughter and May mentor in order to receive
          personal and professional development support and advice from an
          expert within the legal sector. Last year, 50 upReach Associates were
          mentored by a Slaughter and May solicitor.
        </p>
      </div>
    ),
  },
  {
    title:
      "What have been the main successes of the first Law Springboard Programme so far?",
    description: (
      <div>
        <li>
          So far, undergraduates on the Law Springboard have made 24 successful
          applications to legal work experience and vacation schemes.
        </li>
        <li>
          So far, undergraduates have secured offers from 16 different firms, 4
          of which are Magic Circle law firms.
        </li>
        <li>
          As part of the 2019 programme, 25 undergraduates completed work
          experience at Slaughter and May.{" "}
        </li>
        <li>
          100% of those undergraduates who attended the 2019 Springboard events
          would recommend them to another upReach Associate.
        </li>
      </div>
    ),
  },
  {
    title: "How can I get involved in the Law Springboard programme?",
    description: (
      <div>
        <p>
          Applications are now open for students to join the 2020 Law
          Springboard programme. Click the box below to begin your application
          journey.
        </p>
        <img className="img-fluid" src={applyphoto} alt="apply"></img>
      </div>
    ),
  },
];

const carousel = [
  {
    description:
      "Being part of this year's Law Springboard has helped to facilitate my professional development. I have participated in exclusive opportunities I would not otherwise have had access to - like the Law Springboard Launch Event at Slaughter and May - alongside being able to access invaluable 1-to-1 application support from my Programme Coordinator.I have also built essential skills which have helped me to stand out from other candidates, as well as to excel in my applications on the first try. I'm proud to have secured numerous career opportunities as a result, including after an extensive CV review, a legal internship at Vodafone Group's London HQ!",
    image: `${Testimonial1}`,
    author: "- Ismaeel, 2019 Law Springboard Associate",
  },
  {
    description:
      "upReach has given me the confidence to go out there and seek great opportunities With the support and guidance of my mentor, I was able to set goals for myself and aim for higher opportunities at big City law firms without the fear of not being good enough.",
    image: `${Testimonial2}`,
    author: "- Falis, 2019 Law Springboard Associate",
  },
];

const accordionHeading = {
  heading: "",
  include: "",
};

const heading = { title: "Our Programmes" };

const heroImage = { hero: `${hero}` };
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Students />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/law-spring-board-page`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};

const Students = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("lawSpringBoard", fetchData);
  const { data: heroImage } = useQuery("lawImages", fetchHeroImage);
  const mainUrl = process.env.GATSBY_API_URL;
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "LawPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {/* <SectionDivider /> */}
        {newData &&
          newData.showTestimonial &&
          newData.Testimonial.length > 0 && (
            <Testimonial carousel={newData.Testimonial} />
          )}

        {newData && (
          <div className="padding-left-8 padding-right-8 accordion-start">
            <h1>The Law Springboard Programme</h1>
            <p>Exclusively sponsored by</p>
            {newData.showSponsorImage && newData.sponserImage && (
              <img
                className="img-fluid slaughter-img"
                src={mainUrl + newData.sponserImage.url}
                alt="slaughterlogo"
              />
            )}
          </div>
        )}

        {newData && newData.showAccordion && (
          <div className="container-fluid p-0">
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};

// export default Students;
